import { GoogleGenerativeAI, HarmBlockThreshold, HarmCategory } from '@google/generative-ai';

class ChatBotCommunicator {
  constructor(apiKey) {
    this.genAI = new GoogleGenerativeAI(apiKey || 'AIzaSyBpFVWg1k_4g0uK4ULzOvifgsHG-URkQG0');
    this.model = null;
    this.chat = null;

    this.startChat();
  }

  async startChat(force) {
    if (this.chat && !force)
      return;

    const safetySettings = [
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      }
    ];

    
    // Update the model to incorporate safety settings.
    this.model = this.genAI.getGenerativeModel({ 
      model: "gemini-1.5-flash", 
      safetySettings
    });

    // current max token output
    this.model.generationConfig.maxOutputTokens = 8192; 

    // Get chat history
    let history = this.getHistory();

    // Remove non-genAi properties from history
    history.forEach(item => {
      delete item.sender;
      delete item.text;
    });

    // Start the chat session with the initial history
    this.chat = this.model.startChat({ history: history, safetySettings });
  }

  getHistory() {
    try {
      const storedHistory = localStorage.getItem('chatHistory');
      return storedHistory ? JSON.parse(storedHistory) : [];
    } catch (error) {
      console.error("Failed to parse history from localStorage:", error);
      return [];
    }
  }

  setHistory(newMessage) {
    try {
      const currentHistory = this.getHistory();
      const updatedHistory = [...currentHistory, newMessage];
      localStorage.setItem('chatHistory', JSON.stringify(updatedHistory));
    } catch (error) {
      console.error("Failed to save chat history to localStorage:", error);
    }
  }

  clearHistory() {
    try {
      localStorage.removeItem('chatHistory');
      this.startChat(true);
    } catch (error) {
      console.error("Failed to clear chat history from localStorage:", error);
    }
  }

  async generateContent(inputText) {
    const trimmedInput = String(inputText).trim();
    if (!trimmedInput) return;

    try {

      const result = await this.model.generateContentStream(trimmedInput);

      // Collect the full response from the stream
      let response = '';
      for await (const chunk of result.stream) {
        let text = chunk.candidates[0].content.parts[0].text;
        response += text;  
      }

      return { text: response, sender: 'Tess', status: 200 };

    } catch (error) {
      console.error('Error sending message:', error);
      return { text: 'An error occurred while processing your request.', sender: 'Tess', status: 500 };
    }
  }

  async sendMessage(inputText) {
    const trimmedInput = String(inputText).trim();
    if (!trimmedInput) return;

    try {
      
      // Call your custom generator function
      const stream = this.generateContentStream(trimmedInput);
      
      // Collect the full response from the stream
      let response = '';
      for await (const chunk of stream) 
        response += chunk.text;  

      return { text: response, sender: 'Tess', status: 200 };

    } catch (error) {
      console.error('Error sending message:', error);
      return { text: 'An error occurred while processing your request.', sender: 'Tess', status: 500 };
    }
  }

  async *generateContentStream(inputText) {
    if (!this.chat) {
      console.error('Chat not started. Call startChat() before streamGenerateContent().');
      return;
    }

    const trimmedInput = String(inputText).trim();
    if (!trimmedInput) return;

    try {
      // Add the new message to the history
      const userMessage = {
        sender: "user",
        role: "user",
        parts: [{text: trimmedInput}],
        text: trimmedInput
      };
      this.setHistory(userMessage);
      
      const result = await this.chat.sendMessageStream(trimmedInput); 
      
      // Initialize an array to hold all parts of the AI response
      let aiResponseParts = [];

      for await (const chunk of result.stream) {
        const text = await chunk.text();
        
        yield { text: text, sender: 'model', status: 200 };

        // Add the part to aiResponseParts
        aiResponseParts.push({text: text});
      }

      // Add the entire AI response to the history
      const modelHistory = {
        sender: "Tess",
        role: "model",
        parts: aiResponseParts,
        text: aiResponseParts.map(part => part.text).join(' ')
      };
      this.setHistory(modelHistory);

    } catch (error) {
      console.error('Error sending message:', error);
      yield { text: 'An error occurred while processing your request.', sender: 'Tess', status: 500 };
    }
  }
}

export default ChatBotCommunicator;