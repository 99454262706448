import DataService from './DataService';
import CreateProjectDocuments from './CreateProjectDocuments';
//import ChatBotCommunicator from './ChatBotCommunicator';
import { getChatBotInstance } from './ChatBotInstance';
import { Parser, HtmlRenderer } from 'commonmark';
import MarkdownIt from 'markdown-it';
import he from 'he';

class DocumentsClass {

    cleanUpText(text) {
        return text
            .replace(/â/g, '"')  // Replace left double quotation mark
            .replace(/â/g, '"')  // Replace right double quotation mark
            .replace(/â¥8/g, '≥8') // Replace ≥8
            .replace(/â/g, "'")  // Replace left single quotation mark
            .replace(/â/g, "'"); // Replace right single quotation mark
    }

    createDocument(projectDocument, project_id, user_id) {
        const data = {
            project_id: project_id,
            title: projectDocument.title,
            body: projectDocument.body,
            created_by: user_id,
            status: "draft"
        };

        // DataService.createDocument is expected to return a Promise
        return DataService.createDocument(data).then(createdData => {
            if (!createdData) {
                throw new Error('Document creation failed');
            }
            return createdData;
        });
    }

    createHomePage(projectDocument, project_id, user_id) {
        const data = {
            project_id: project_id,
            title: projectDocument.title,
            body: "<h2>"+projectDocument.title+" Home</h2><p>This is the home page for your project. Update this page with information about your project.</p> <h2>Key Features</h2> <ul> <li><b>Powerful Text Editor:</b> Experience a rich text editing experience with a wide array of formatting options. </li> <li> <s>Feature that has been removed</s> </li> <li><b>Other Amazing Features:</b> Our project offers many other features to help you achieve your goals. </li> </ul> <h3>Text Formatting Showcase</h3> <p>Our text editor allows you to create visually appealing and structured documents:</p> <blockquote>This is a blockquote for emphasizing important information.</blockquote> <p>You can use headers to organize your content:</p> <h4>Heading 4</h4> <p>Create ordered and unordered lists:</p> <ol> <li>This is an ordered list item.</li> <li>Another ordered list item.</li> </ol> <ul> <li>This is an unordered list item.</li> <li>Another unordered list item.</li> </ul> <p>Write code snippets using the <code>code-block</code> style.</p> <p>Emphasize text with <b>bold</b>, <i>italics</i>, or <u>underline</u>.</p> <p>And even include mathematical expressions like x<sup>2</sup> + y<sub>2</sub>.</p>",
            created_by: user_id,
            is_home: true,
            status: "published"
        };

        // DataService.createDocument is expected to return a Promise
        return DataService.createDocument(data).then(createdData => {
            if (!createdData) {
                throw new Error('Document creation failed');
            }
            return createdData;
        });
    }

    generateDocument(project, documentType, Auth0_user, projectContext) {
        const reader = new Parser();
        const writer = new HtmlRenderer();
        const md = new MarkdownIt({ html: true, linkify: true, typographer: true, tables: true });
        const description = project.description;
        const projectName = project.name;
        //const chatBotCommunicator = new ChatBotCommunicator('AIzaSyBpFVWg1k_4g0uK4ULzOvifgsHG-URkQG0');
        const chatBotCommunicator = getChatBotInstance();
        const documentGenerator = new CreateProjectDocuments(chatBotCommunicator);        
    
        return documentGenerator.createDocument(documentType, description, projectName, projectContext)
            .then(response => {
                const result = md.render(this.cleanUpText(response.text)); // convert to HTML
    
                const projectDocument = {
                    body: result, // use the HTML here
                    title: documentType,
                    project_id: project.project_id,
                    created_by: Auth0_user.sub,
                    status: "draft"
                };
    
                return DataService.createDocument(projectDocument)
                    .then(createdData => {
                        if (!createdData) {
                            throw new Error('Document creation failed');
                        }
                        return createdData;
                    }
                );
            }
        );
    }
}

export default DocumentsClass;