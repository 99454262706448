//import ChatBotCommunicator from './ChatBotCommunicator.js';
import { getChatBotInstance } from './ChatBotInstance.js';
import GenerateEpicsPrompt from '../prompts/GenerateEpicsPrompt.js';
import GenerateStoriesWithEpicPrompt from '../prompts/GenerateStoriesWithEpicPrompt.js';
import GenerateStoriesWithoutEpicPrompt from '../prompts/GenerateStoriesWithoutEpicPrompt.js';

class GenerateProjectPlan {
    constructor(projectName, projectDescription, selectedEpic, epics) {
        this.projectName = projectName;
        this.projectDescription = projectDescription;
        this.selectedEpic = selectedEpic;
        this.epics = epics;
        //this.chatBotCommunicator = new ChatBotCommunicator('AIzaSyBpFVWg1k_4g0uK4ULzOvifgsHG-URkQG0');
        this.chatBotCommunicator = getChatBotInstance();
    }

    // Method to generate epics
    async generateEpics(retryCount = 0, projectContext) {
        const maxRetries = 3; // Maximum number of retries

        let prompt = new GenerateEpicsPrompt(this.projectName, this.projectDescription, projectContext);

        //const inputText = `You will act as a Business Analyst and Project Manager for a new project based on the PROJECT_NAME and the PROJECT_DESCR defined in the Project Details. Do a deep analysis of the project needs and the steps that will be required to complete it, then create all the epics necessary to complete the project. Do not respond without giving epics. Do not use any placeholders, instead using the provided links or your existing knowledge to fill define the epic name and description. Do not include any explanations or instructions. Only output the epic names and descriptions in a raw, single-line JSON object with no markup, no markdown, and with normal quotes instead of smart quotes. Project Details: {"PROJECT_NAME":${this.projectName},"PROJECT_DESCR":${this.projectDescription},"JIRA_PROJECT_API_LINK": "https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-projects/#api-group-projects"} Response Structure: {"epics":[{"name":"epic name","description":"epic description"}]}`;
        const inputText = prompt.build();
        const response = await this.chatBotCommunicator.generateContent(inputText);

        if (response.status === 500) {
            console.error('AI Server error:', response.text);
            return null;
        }

        if (response.status === 200) {
            let responseData;
            try {
                responseData = JSON.parse(response.text);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
            }

            // If the epics array is empty and we haven't reached the maximum number of retries, retry immediately
            if (responseData.epics.length === 0 && retryCount < maxRetries) {
                return this.generateEpics(retryCount + 1);
            }

            // If we've reached the maximum number of retries and still no epics, return an error
            if (responseData.epics.length === 0 && retryCount >= maxRetries) {
                console.error('No epics found after maximum number of retries');
                return null;
            }

            const epics = responseData.epics.map(epic => ({
                name: epic.name,
                description: epic.description
            }));

            return { epics };
        } else {
            console.error('Error generating epics:', response.text);
            return null;
        }
    }

    // Method to generate  MORE epics
    async generateMoreEpics(epics,retryCount = 0, projectContext) {
        const maxRetries = 3; // Maximum number of retries

        let prompt = new GenerateEpicsPrompt(this.projectName, this.projectDescription, projectContext);

        //const inputText = `You will act as a Business Analyst and Project Manager for a new project based on the PROJECT_NAME and the PROJECT_DESCR defined in the Project Details. Do a deep analysis of the project needs and the steps that will be required to complete it, then create all the epics necessary to complete the project. Do not respond without giving epics. Do not use any placeholders, instead using the provided links or your existing knowledge to fill define the epic name and description. Do not include any explanations or instructions. Only output the epic names and descriptions in a raw, single-line JSON object with no markup, no markdown, and with normal quotes instead of smart quotes. Project Details: {"PROJECT_NAME":${this.projectName},"PROJECT_DESCR":${this.projectDescription},"JIRA_PROJECT_API_LINK": "https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-projects/#api-group-projects"} Response Structure: {"epics":[{"name":"epic name","description":"epic description"}]}`;
        const inputText = prompt.build();
        const response = await this.chatBotCommunicator.generateContent(inputText);

        if (response.status === 500) {
            console.error('AI Server error:', response.text);
            return null;
        }

        if (response.status === 200) {
            let responseData;
            try {
                responseData = JSON.parse(response.text);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
            }

            // If the epics array is empty and we haven't reached the maximum number of retries, retry immediately
            if (responseData.epics.length === 0 && retryCount < maxRetries) {
                return this.generateEpics(retryCount + 1);
            }

            // If we've reached the maximum number of retries and still no epics, return an error
            if (responseData.epics.length === 0 && retryCount >= maxRetries) {
                console.error('No epics found after maximum number of retries');
                return null;
            }

            const epics = responseData.epics.map(epic => ({
                name: epic.name,
                description: epic.description
            }));

            return { epics };
        } else {
            console.error('Error generating epics:', response.text);
            return null;
        }
    }

    // Method to generate stories when an epic IS selected
    async generateStoriesWithEpic(selectedEpic, stories, project_id, projectContext) {

        let prompt = new GenerateStoriesWithEpicPrompt(this.projectName, this.projectDescription, selectedEpic, stories, projectContext);

        let inputText = prompt.build();
        // if (stories == null) {
        //     inputText = `You will act as the entire Scrum team for a new project. Consider the PROJECT_TITLE and PROJECT_DESCRIPTION, then create all the user stories necessary to complete only the ${selectedEpic.name} defined as ${selectedEpic.description}. Your response must adhere to the Required Response Format. All elements in the Required Response Format must have content and cannot be null. Assume a single developer can execute 8 story points every 2 week sprint. Use your best judgment when pointing stories, ensuring every story has points associated with them. Do not use any placeholders, instead using the provided links or defaults to fill in all the details completely. Do not include any explanations or instructions. Format your response into a raw single-line JSON object that contains all the stories without any markdown or markup and with normal quotes instead of smart quotes. Don't output the project information in your response JSON. Project Details:{"PROJECT_TITLE":"${this.projectName}","PROJECT_DESCRIPTION":"${this.projectDescription}","SELECTED_EPIC":"${this.selectedEpic.name}","SELECTED_EPIC_DESCRIPTION":"${selectedEpic.description}"} Required Response Format:[ "stories": { "project_id": project_id,  "epic_id": epic_id,  "sprint_id": null,  "title": title,  "description": description,  "acceptance_criteria": acceptance_criteria,  "priority": 999,  "status": "todo",  "story_points": story_points,  "assigned_user": null,  "story_location": "backlog"}]`;
        // } else {
        //     const storyTitles = stories.map(story => story.title).join(', ');
        //     inputText = `You need to create additional stories for the selected epic titled ${selectedEpic.name} with the following description: ${selectedEpic.description}. The existing stories are: ${storyTitles}. Please create all the user stories necessary to complete the selected epic. Your response must adhere to the Required Response Format. All elements in the Required Response Format must have content and cannot be null. Assume a single developer can execute 8 story points every 2 week sprint. Use your best judgment when pointing stories, ensuring every story has points associated with them. Do not use any placeholders, instead using the provided links or defaults to fill in all the details completely. Do not include any explanations or instructions. Format your response into a raw single-line JSON object that contains all the stories without any markdown or markup and with normal quotes instead of smart quotes. Don't output the project information in your response JSON. Project Details:{"PROJECT_TITLE":"${this.projectName}","PROJECT_DESCRIPTION":"${this.projectDescription}","SELECTED_EPIC":"${this.selectedEpic.name}"} Required Response Format:[ "stories": { "project_id": project_id,  "epic_id": epic_id,  "sprint_id": null,  "title": title,  "description": description,  "acceptance_criteria": acceptance_criteria,  "priority": 999,  "status": "todo",  "story_points": story_points,  "assigned_user": null,  "story_location": "backlog"}]`;
        // }
        const response = await this.chatBotCommunicator.generateContent(inputText);
        if (response.status === 500) {
            console.error('Server error when generating stories:', response.text);
            return null;
        } else if (response.status === 200) {
            let responseData;
            try {
                // Check if response.text is defined and is a valid JSON string
                if (response.text && typeof response.text === 'string') {
                    responseData = JSON.parse(response.text);
                } else {
                    console.error('Invalid response:', response.text);
                    return null;
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
            }
            // Check if stories exists and is an array, if not wrap it in an array or default to an empty array
            let storiesArray;
            if (responseData.stories) {
                storiesArray = Array.isArray(responseData.stories) 
                    ? responseData.stories 
                    : [responseData.stories];
            } else {
                storiesArray = [];
            }
            const stories = storiesArray.map(story => ({
                title: story.title,
                project_id: project_id,
                description: story.description,
                acceptance_criteria: story.acceptance_criteria,
                priority: 999,
                story_points: story.story_points,
            }));
            return { stories };
        } else {
            console.error('Error generating stories:', response.text);
            return null;
        }
    }

    // Method to generate stories when an epic IS NOT selected
    async generateStoriesWithoutEpic(stories, project_id, projectContext) {

    let prompt = new GenerateStoriesWithoutEpicPrompt(this.projectName, this.projectDescription, stories, projectContext);    
        
    let inputText = prompt.build();
    // if (stories !== null) {
    //     inputText = `You will act as the entire Scrum team for a new project. Consider the PROJECT_TITLE and PROJECT_DESCRIPTION, then create all the user stories necessary to complete the project. Your response must adhere to the Required Response Format. All elements in the Required Response Format must have content and cannot be null. Assume a single developer can execute 8 story points every 2 week sprint. Use your best judgment when pointing stories, ensuring every story has points associated with them. Do not use any placeholders, instead using the provided links or defaults to fill in all the details completely. Do not include any explanations or instructions. Format your response into a raw single-line JSON object that contains all the stories without any markdown or markup and with normal quotes instead of smart quotes. Don't output the project information in your response JSON. Project Details:{"PROJECT_TITLE":"${this.projectName}","PROJECT_DESCRIPTION":"${this.projectDescription}"} Required Response Format:[ "stories": { "project_id": project_id,  "epic_id": epic_id,  "sprint_id": null,  "title": title,  "description": description,  "acceptance_criteria": acceptance_criteria,  "priority": 999,  "status": "todo",  "story_points": story_points,  "assigned_user": null,  "story_location": "backlog"}]`;
    // } else {
    //     const storyTitles = stories.map(story => story.title).join(', ');
    //     inputText = `You need to create additional stories for the project. The existing stories are: ${storyTitles}. Please create all the user stories necessary to complete the project. Your response must adhere to the Required Response Format. All elements in the Required Response Format must have content and cannot be null. Assume a single developer can execute 8 story points every 2 week sprint. Use your best judgment when pointing stories, ensuring every story has points associated with them. Do not use any placeholders, instead using the provided links or defaults to fill in all the details completely. Do not include any explanations or instructions. Format your response into a raw single-line JSON object that contains all the stories without any markdown or markup and with normal quotes instead of smart quotes. Don't output the project information in your response JSON. Project Details:{"PROJECT_TITLE":"${this.projectName}","PROJECT_DESCRIPTION":"${this.projectDescription}"} Required Response Format:[ "stories": { "project_id": project_id,  "epic_id": epic_id,  "sprint_id": null,  "title": title,  "description": description,  "acceptance_criteria": acceptance_criteria,  "priority": 999,  "status": "todo",  "story_points": story_points,  "assigned_user": null,  "story_location": "backlog"}]`;
    // }
    const response = await this.chatBotCommunicator.generateContent(inputText);
    // console.log('Generate Project Plan Stories Response:', response);
    if (response.status === 500) {
        console.error('Server error when generating stories:', response.text);
        return "I'm sorry, something went wrong when I was generating stories. Please try again." + response.text;
    } else if (response.status === 200) {
        try {
            let responseText = response.text;
            let responseData;
            try {
                responseData = JSON.parse(responseText);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                console.error('Invalid JSON:', responseText);
                return null;
            }
            // console.log('Generate Project Plan Stories Response Data:', responseData);

            // Check if stories exists and is an array, if not wrap it in an array or default to an empty array
            let storiesArray;
            if (responseData.stories) {
                storiesArray = Array.isArray(responseData.stories) 
                    ? responseData.stories 
                    : [responseData.stories];
            } else {
                storiesArray = [];
            }
            const stories = storiesArray.map(story => ({
                title: story.title,
                project_id: project_id,
                description: story.description,
                acceptance_criteria: story.acceptance_criteria,
                priority: 999,
                story_points: story.story_points,
            }));
            return { stories };
        } catch (error) {
            console.error('Error generating stories:', error);
            return [];
        }
    } else {
        console.error('Error generating stories:', response.text);
        return [];
    }
}
}

export default GenerateProjectPlan;