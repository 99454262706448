import React, { useState } from 'react';
import { Box, Popper, Paper, Avatar, Typography, IconButton, Tooltip } from '@mui/material';
import MessageDisplay from './MessageDisplay/MessageDisplay';
import MessageInput from './MessageInput/MessageInput';
import PlaylistRemove from '@mui/icons-material/PlaylistRemove';
import TessAvatarImage from '../../../assets/images/Tess-Headshot-Small.webp';
import AiChatWindowController from './AiChatWindow.controller';
//import ChatBotCommunicator from '../../../classes/ChatBotCommunicator';
import { getChatBotInstance } from '../../../classes/ChatBotInstance';

export default function AiChatWindow({ anchorEl, handleClick, handleClose}) {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [isProcessing, setIsProcessing] = useState(false);
    const aiChatWindowController = new AiChatWindowController();
    //const chatBotCommunicator = new ChatBotCommunicator();
    const chatBotCommunicator = getChatBotInstance();
    //const storedMessages = JSON.parse(localStorage.getItem('messages'));
    const storedMessages = chatBotCommunicator.getHistory();
    const [messages, setMessages] = useState(storedMessages || []);
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const inputObject = []; //{ text: messenger, sender: user, created_on: dateTime };

    // aiChatWindowController.sendMessage(inputObject, messages, setMessages, setIsProcessing, setLastUpdated);

    return (
        <Box sx={{ position: 'fixed', right: 100, bottom: 105 }}>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="top-end"
                disablePortal={false}
                modifiers={[
                    {
                        name: 'flip',
                        options: {
                            altBoundary: true,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            altAxis: true,
                            tether: false,
                        },
                    },
                ]}
            >
                <Paper elevation={3} sx={{ width: '60vh', height: '80vh', maxHeight: '80vh', overflow: 'hidden', zIndex: 9999, borderRadius: '10px', marginBottom: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box
                        display='flex'
                        alignItems='center'
                        p={1}
                        m={0}                
                        sx={{
                            backgroundColor: '#707387',
                            borderRadius: '10px 10px 0 0',
                            height: '3rem',
                        }}
                    >
                        <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ width: '200%' }}>
                            <Box>
                                <Avatar src={TessAvatarImage} sx={{ width: '2rem', height: '2rem', marginRight: '0.5rem', }} />
                            </Box>
                            <Box>
                                <Typography variant='body1' color='white' sx={{ fontSize: '1.2rem' }}>TESS</Typography>
                            </Box>
                            <Box>
                                <Tooltip title='Clear Chat' placement='bottom'>
                                    <IconButton 
                                        sx={{ 
                                            color: 'white', 
                                            marginRight: '5px',
                                            '&:hover': {
                                            backgroundColor: '#c62828',
                                            },
                                            width: '35px',
                                            height: '35px',
                                        }} 
                                        onClick={() => aiChatWindowController.clearMessages(setMessages)}
                                    >
                                        <PlaylistRemove />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                    <MessageDisplay messages={messages} />
                    <MessageInput messages={messages} setMessages={setMessages} sendMessage={aiChatWindowController.sendMessage} isProcessing={isProcessing} setIsProcessing={setIsProcessing} />
                </Paper>
            </Popper>
        </Box>
    )
}