import { useState, useEffect } from 'react';
//import ChatBotCommunicator from '../../../classes/ChatBotCommunicator'; // Assuming you have a chatBotCommunicator module
import { getChatBotInstance } from '../../../classes/ChatBotInstance';

export default class AiChatWindowController {
    
    constructor() {
        this.anchorEl = null;
        this.chatBotCommunicator = getChatBotInstance();
    }

    clearMessages = (setMessages) => {
        setMessages([]);
        //localStorage.removeItem('messages');
        this.chatBotCommunicator.clearHistory();
    };

    handleClick(event) {
        this.anchorEl = event.currentTarget;
        // console.log('Anchor El:', this.anchorEl);
    }

    handleClose() {
        this.anchorEl = null;
    }

    async sendMessage(inputObject, messages, setMessages, setIsProcessing, setLastUpdated) {
        //const chatBotCommunicator = new ChatBotCommunicator('AIzaSyBpFVWg1k_4g0uK4ULzOvifgsHG-URkQG0');
        const chatBotCommunicator = getChatBotInstance();

        // Check if there are more than 100 messages
        if (messages.length > 98) {
            // Remove the first message from the array
            messages.shift();
        }
        
        const text = inputObject.text.trim();
        if (text) {
          const sender = inputObject.sender === 'Tess' ? 'model' : inputObject.sender;
          setIsProcessing(true);
          let updatedMessages = [
            ...messages,
            { text: text, sender: sender },
          ];
          setMessages(updatedMessages); // Set messages immediately after user's message is added
          let isFirstResponse = true;
          try {
            if (messages.length == 0) {
              //const storedMessages = JSON.parse(localStorage.getItem('messages'));
              const storedMessages = chatBotCommunicator.getHistory();
            //   console.log("Starting chat with messages:", storedMessages);
              if (storedMessages && storedMessages.length > 0 && storedMessages[0].sender === 'user') {
                const formattedStoredMessages = storedMessages.map(message => ({
                  role: message.sender === 'Tess' ? 'model' : message.sender,
                  parts: [{ text: message.text }],
                }));
                await chatBotCommunicator.startChat(formattedStoredMessages);
              }
            }
            const responseStream = chatBotCommunicator.generateContentStream(text, messages);
            for await (const response of responseStream) {
                if (response && typeof response === 'object' && response.text) {
                    setMessages(prevMessages => {
                        let updatedMessages;
                        if (isFirstResponse) {
                            // Create a new message for the first response
                            updatedMessages = [
                                ...prevMessages,
                                { text: response.text, sender: 'Tess' },
                            ];
                            isFirstResponse = false;
                        } else {
                            // Append subsequent responses to the last message
                            updatedMessages = [...prevMessages];
                            updatedMessages[updatedMessages.length - 1].text += ' ' + response.text;
                        }
                        //localStorage.setItem('messages', JSON.stringify(updatedMessages)); // Save messages to local storage immediately after each AI's response is added
                        setLastUpdated(Date.now()); // Trigger a re-render
                        return updatedMessages;
                    });
                }
            }
            } catch (error) {
                console.error("Error sending message:", error);
            } finally {
                setIsProcessing(false);
            }
        }
    }
}