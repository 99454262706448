import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { List, ListItem, ListItemText, Avatar, ListItemAvatar, CircularProgress, Box, Typography, Card, CardContent, Button } from '@mui/material';
import TessAvatarImage from '../assets/images/Tess-Headshot-Small.webp'; // Import Tess's avatar image
import { useAuth0 } from '@auth0/auth0-react'; // Import the useAuth0 hook
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
//import ChatBotCommunicator from '../classes/ChatBotCommunicator';
import { getChatBotInstance } from '../classes/ChatBotInstance';

function MessageList({ messages, clearMessages, isProcessing, lastUpdated }) {
  const { user } = useAuth0();
  const messageEndRef = useRef(null);
  const [isBottomVisible, setIsBottomVisible] = useState(false);
  const bottomRef = useRef(null);
  const defaultMessage = {
    sender: 'Tess',
    text: `Hello! I'm Tess, Meteor's AI Assistant. How can I help you?`
  };
  const chatBotCommunicator = getChatBotInstance();

  const [localMessages, setLocalMessages] = useState(() => {
    //const savedMessages = JSON.parse(localStorage.getItem('messages'));
    const savedMessages = chatBotCommunicator.getHistory();
    return savedMessages || [defaultMessage];
  });

  const handleClearMessages = () => {
  clearMessages();
  setLocalMessages([defaultMessage]);
  setCurrentChatbotMessage(null);
  setLastMessage(null);
  //TODO: why save the default non-correct formated message if it defaults above?
  //localStorage.setItem('messages', JSON.stringify([defaultMessage]));
};

const [currentChatbotMessage, setCurrentChatbotMessage] = useState(null);
const [lastMessage, setLastMessage] = useState(null);

useEffect(() => {
  setLocalMessages(messages);
}, [messages]);

useEffect(() => {
  // Re-render the component
}, [lastUpdated]);
  
useEffect(() => {
  if (messageEndRef.current) {
    messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [localMessages, messages, messages.length]);

useEffect(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      setIsBottomVisible(entry.isIntersecting);
    },
    { threshold: 1.0 }
  );

  if (bottomRef.current) {
    observer.observe(bottomRef.current);
  }

  return () => {
    if (bottomRef.current) {
      observer.unobserve(bottomRef.current);
    }
  };
}, []);

  return (
    <>
      {localMessages.length === 0 || (localMessages.length === 1 && localMessages[0].sender === defaultMessage.sender && localMessages[0].text === defaultMessage.text) ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="70vh" paddingLeft="1.5rem">
          <ListItemAvatar sx={{ marginTop: '13%' }}>
            <Avatar alt="Tess" src={TessAvatarImage} />
          </ListItemAvatar>
          <Typography color="textPrimary" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            <ReactMarkdown>{defaultMessage.text}</ReactMarkdown>
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: '20%' }}>
            <Card variant='outlined' sx={{ minWidth: '30%', maxWidth: '30%', borderRadius: '10px' }}>
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} >I want to build a mobile app for...</Typography>
                <Typography variant="body2" color="grey"><p>Have Tess help you conceptualize your project so you can start off your planning on the right foot.</p></Typography>
              </CardContent>
            </Card>
            <Card variant='outlined' sx={{ minWidth: '30%', maxWidth: '30%', borderRadius: '10px' }}>
            <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} >What materials will I need to complete a project for...</Typography>
                <Typography variant="body2" color="grey"><p>Tess can define tools, materials, and other requirements you may need for your projects.</p></Typography>
              </CardContent>
            </Card>
            <Card variant='outlined' sx={{ minWidth: '30%', maxWidth: '30%', borderRadius: '10px' }}>
            <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} >What is Scrum...</Typography>
                <Typography variant="body2" color="grey"><p>New to project management? Tess is the expert who can quickly teach you concepts, frameworks, tools, and more...</p></Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      ) : (
        <List className='custom-scrollbar'>
          {localMessages.map((message, index) => (
            message && <ListItem key={index} alignItems="flex-start">
              <ListItemAvatar>
                {message.sender === 'user' ? (
                  <Avatar src={user.picture} /> // Avatar for user
                ) : (
                  <Avatar alt="Tess" src={TessAvatarImage} /> // Tess's avatar image
                )}
              </ListItemAvatar>
              <ListItemText
                primary={message.sender === 'user' ? user.name : 'Tess'}
                secondary={
                  <Typography color="textPrimary">
                    {message.text.startsWith('```') && message.text.endsWith('```') ? (
                      <SyntaxHighlighter language="javascript" style={darcula}>
                        {message.text.slice(3, -3)}
                      </SyntaxHighlighter>
                    ) : (
                      <ReactMarkdown>{message.text}</ReactMarkdown>
                    )}
                  </Typography>
                }
              />
            </ListItem>
          ))}
          {/* {isProcessing && (
            <ListItem>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="primary" size={20} />
              </Box>
            </ListItem>
          )} */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {localMessages.length > 1 && !isProcessing && (
              <ListItem>
                <Button onClick={handleClearMessages}>Clear Chat</Button>
              </ListItem>
            )}
          </Box>
          <div ref={messageEndRef} />
        </List>
      )}
      {!isBottomVisible && (
        <IconButton 
          onClick={() => messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })}
          sx={{
            position: 'fixed',
            bottom: '100px',
            right: '50px',
            zIndex: 1000,
            backgroundColor: '#fff',
            boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              backgroundColor: '#F4F4F4',
            },
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
      )}
      <div ref={bottomRef} />
    </>
  );
}

export default MessageList;